import { setBaseUrl, useMiddleware } from '@exceptionless/fetchclient';
import { error } from '@sveltejs/kit';

useMiddleware(async (ctx, next) => {
    await next();

    if (ctx.response?.status === 404 && !ctx.options.expectedStatusCodes?.includes(404)) {
        throw error(404, 'Not found');
    }
});

setBaseUrl('api');
